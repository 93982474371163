<!--
 * @Author: wxy
 * @Date: 2022-07-06 11:22:55
 * @LastEditTime: 2022-10-29 16:03:09
 * @Description: 服务咨询详情
-->
<template>
  <div class="serverConsultingDetail">
    <div class="header">
      <ds-header title="服务咨询详情" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <div class="content">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item label="企业名称"> {{ form.enterpriseName }} </a-form-model-item>
        <a-form-model-item label="咨询人"> {{ form.createIdShow }} </a-form-model-item>
        <a-form-model-item label="咨询联系人"> {{ form.contactName }} </a-form-model-item>
        <a-form-model-item label="咨询联系人联系方式"> {{ form.contactPhone }}</a-form-model-item>
        <a-form-model-item label="咨询标题"> <span v-html="form.consultingTitle"></span></a-form-model-item>
        <a-form-model-item label="服务类型"> {{ form.serverTypeName }} </a-form-model-item>
        <a-form-model-item label="所属项目"> {{ form.projectName }} </a-form-model-item>
        <a-form-model-item label="咨询内容"><span v-html="form.consultingContent"></span></a-form-model-item>
        <a-form-model-item label="意向供应商"> {{ form.supplierNames || "暂无" }} </a-form-model-item>
        <a-form-model-item label="咨询提交时间"> {{ form.gmtCreate }} </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
export default {
  data() {
    return {
      form: {},
    };
  },
  created() {
    // 获取详情
    this.getList();
  },
  methods: {
    // 获取详情
    async getList() {
      try {
        const res = await api.serverConsultationDetail(this.$route.query.id);
        if (res.code === "200") {
          this.form = res.data;
          this.form.consultingContent = res.data.consultingContent
            .replaceAll(/\r\n/g, "<br>")
            .replaceAll(/\r/g, "<br>")
            .replaceAll(/\n/g, "<br>");
          this.form.consultingTitle = res.data.consultingTitle
            .replaceAll(/\r\n/g, "<br>")
            .replaceAll(/\r/g, "<br>")
            .replaceAll(/\n/g, "<br>");
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.content {
  width: 70%;
  margin: 10px auto;
}
</style>